import { Component, inject, OnDestroy, OnInit, output } from '@angular/core';
import { MomentService } from '@fc-core';
import { Store } from '@ngrx/store';
import { NotificationsStore } from '@fc-core/notifications/store/notifications.store';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'fc-events-tab',
  templateUrl: './events-tab.component.html',
  styleUrls: ['./events-tab.component.scss'],
  standalone: false,
})
export class EventsTabComponent implements OnInit, OnDestroy {
  momentService = inject(MomentService);
  notificationsStore = inject(NotificationsStore);
  store = inject(Store);

  closePopup = output();

  ngOnInit() {
    this.notificationsStore.loadEvents();
  }

  ngOnDestroy() {
    this.notificationsStore.reset();
  }

  onTabChange(event: MatTabChangeEvent) {
    const tabLabel = event.tab.textLabel;
    this.notificationsStore.selectTab(tabLabel.toLowerCase());
    this.notificationsStore.loadEvents();
  }
}
