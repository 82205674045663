import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnDestroy,
  output,
  Signal,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'fc-side-popup',
  imports: [NgClass],
  template: `
    <div [ngClass]="position()" class="side-popup">
      <ng-content />
    </div>
  `,
  styles: `
    @use 'var' as *;

    .side-popup {
      border-radius: 12px;
      background-color: $white;
      box-shadow: $box-shadow-3xl;
      position: fixed;
      width: 550px;
      z-index: 11;
      bottom: 10px;
      top: 70px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      @media (max-width: $sm-size) {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        top: 60px;
        border-radius: 0;
      }
    }

    .popup-header {
      border-bottom: 1px solid $secondary-100;
      padding: 24px;
    }

    .scroll-container {
      height: 100%;
      overflow-y: auto;
    }

    .side-popup.left {
      left: 10px;
    }

    .side-popup.right {
      right: 10px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePopupComponent implements OnDestroy {
  title = input<string>();
  showHeader = input<boolean>(true);
  position: Signal<'left' | 'right'> = input('right');
  closePopup = output();

  ngOnDestroy() {
    this.closePopup.emit();
  }
}
