<div class="p-24 flex-row justify-between align-center">
  <div class="h2">Notifications</div>

  <fc-text-button
    (click)="notificationsStore.markAllAsRead()"
    class="ml-auto"
    color="primary"
    size="medium"
  >
    Mark all as read
  </fc-text-button>
  <div class="actions">
    <fc-icon-button (click)="closePopup.emit()">
      <fc-icon icon="icon-clear"></fc-icon>
    </fc-icon-button>
  </div>
</div>

<div class="h-full overflow-hidden">
  <mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab label="All">
      <fc-event-list />
    </mat-tab>
    <mat-tab label="Event">
      <fc-event-list />
    </mat-tab>
    <mat-tab label="Chat">
      <fc-event-list />
    </mat-tab>
  </mat-tab-group>
</div>
