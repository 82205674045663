import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { BackendResponse } from '@fc-core/models/response.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  getEventsHistory(
    next?: string,
    selectedTab?: string,
  ): Promise<{ events: EventHistoryItem[]; next: string }> {
    const url = next ? next : environment.apiUrl + 'api/notifications/';
    let params = new HttpParams().set('page_size', '20');
    if (selectedTab !== 'all') {
      params = params.set('type', selectedTab);
    }
    return lastValueFrom(
      this.http
        .get<BackendResponse<EventHistoryItem[]>>(url, { params: params })
        .pipe(
          map((events) => {
            return { events: events.results, next: events.next };
          }),
        ),
    );
  }

  markAsRead(eventId: string): Promise<EventHistoryItem> {
    return lastValueFrom(
      this.http.post<EventHistoryItem>(
        environment.apiUrl + 'api/notifications/' + eventId + '/read/',
        {},
      ),
    );
  }

  markAllAsRead(ids: string[]): Promise<string[]> {
    return lastValueFrom(
      this.http
        .post<{ ids: string[] }>(
          environment.apiUrl + 'api/notifications/bulk_read/',
          {
            ids,
          },
        )
        .pipe(
          map((response: { ids: string[] }) => {
            return response.ids;
          }),
        ),
    );
  }
}
