import * as amplitude from '@amplitude/analytics-browser';
import { TokenData } from '../../auth/models/token-data';

export function triggerAnalyticsEvent(event: string): void {
  const tokenData: TokenData = JSON.parse(
    atob(localStorage.getItem('token').split('.')[1]),
  );
  amplitude.track(event, {
    user_id: tokenData.employee_id,
    timestamp: new Date().toISOString(),
  });
}
