<div
  class="popup-header p-24 flex-row justify-between align-center text-gray-500 border-b border-gray-100"
>
  <div class="h1">News</div>
  <fc-icon-button (onClick)="closePopup.emit()">
    <fc-icon icon="icon-clear"></fc-icon>
  </fc-icon-button>
</div>

<div class="news-list h-full overflow-auto">
  <div class="news-item" *ngFor="let newsItem of news()">
    <div class="news-subtitle subtitle color-secondary">
      {{ newsItem.meta.first_published_at | momentFormat: 'MMMM Do YYYY' }}
    </div>
    <div class="news-title h2">{{ newsItem.title }}</div>
    <img
      class="news-image"
      [src]="newsItem.preview_image.meta.download_url"
      alt="news-image"
    />
    <div class="news-content">
      {{ newsItem.description }}
      <a
        [href]="newsItem.meta.html_url"
        target="_blank"
        class="news-read-more color-main"
        >Read more</a
      >
    </div>
    <hr class="divider" />
  </div>
</div>
