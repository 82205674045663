import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { EventsTabComponent } from './events-tab/events-tab.component';
import { NewsTabComponent } from './news-tab/news-tab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EmptyStateModule } from '@fc-shared/ui/empty-state/empty-state.module';
import { ImageLoaderModule } from '@fc-shared/ui/image-loader/image-loader.module';
import { MatRippleModule } from '@angular/material/core';
import { MomentFormatPipeModule } from '@fc-shared/pipes/moment-format-pipe/moment-format-pipe.module';
import { SkeletonModule } from '@fc-shared/ui/skeleton/skeleton.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollNearEndDirective } from '@fc-shared/directives/scroll-near-end.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { StatusTagComponent } from '@fc-shared/ui/status-tag/status-tag.component';
import { TextButtonComponent } from '@fc-shared/ui/buttons/text-button.component';
import { EventItemComponent } from '@fc-core/notifications/events-tab/event-item.component';
import { EventListComponent } from '@fc-core/notifications/events-tab/event-list.component';

@NgModule({
  declarations: [
    NotificationContainerComponent,
    EventsTabComponent,
    NewsTabComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    EmptyStateModule,
    ImageLoaderModule,
    MatRippleModule,
    MomentFormatPipeModule,
    SkeletonModule,
    ScrollingModule,
    ScrollNearEndDirective,
    MatProgressSpinnerModule,
    IconButtonComponent,
    IconComponent,
    RouterLink,
    StatusTagComponent,
    RouterLinkActive,
    TextButtonComponent,
    EventItemComponent,
    EventListComponent,
  ],
  exports: [
    NotificationContainerComponent,
    NewsTabComponent,
    EventsTabComponent,
  ],
})
export class NotificationsModule {}
