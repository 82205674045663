import { inject, Injectable } from '@angular/core';
import { Store } from '@fc-core/store-utils/store';
import { UpdateState } from '@fc-core/store-utils/update-state';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { NotificationsService } from '@fc-core/notifications/services/notifications.service';

export interface NotificationsState {
  events: EventHistoryItem[];
  next: string | null;
  loading: boolean;
  loadingMore: boolean;
  selectedTab: string;
}

const initialState: NotificationsState = {
  events: [],
  next: null,
  loading: false,
  loadingMore: false,
  selectedTab: 'all',
};

@Injectable({ providedIn: 'root' })
export class NotificationsStore extends Store<NotificationsState> {
  api = inject(NotificationsService);

  constructor() {
    super(initialState);
  }

  async loadEvents(): Promise<void> {
    this.update({ loading: true });
    const response = await this.api.getEventsHistory(
      null,
      this._state().selectedTab,
    );
    this.update({ events: response.events, loading: false });
    if (response.next) this.update({ next: response.next });
  }

  async loadMoreEvents(): Promise<void> {
    if (!this._state().next) return;
    this.update({ loadingMore: true });
    const events = await this.api.getEventsHistory(
      this._state().next,
      this._state().selectedTab,
    );
    this.update({
      events: [...this._state().events, ...events.events],
      loadingMore: false,
    });
    if (events.next) this.update({ next: events.next });
  }

  async markAsRead(eventId: string): Promise<void> {
    const updatedEvent = await this.api.markAsRead(eventId);
    this.update({
      events: this._state().events.map((e) =>
        e.id === updatedEvent.id ? updatedEvent : e,
      ),
    });
  }

  async markAllAsRead(): Promise<void> {
    const ids = this._state()
      .events.filter((e) => !e.read)
      .map((e) => e.id);
    if (!ids.length) return;
    const updatedIds = await this.api.markAllAsRead(ids);
    this.update({
      events: this._state().events.map((e) => {
        if (updatedIds && updatedIds.includes(e.id)) {
          return { ...e, read: true };
        }
        return e;
      }),
    });
  }

  reset(): void {
    this.update(initialState);
  }

  @UpdateState selectTab(tab: string, draft?: NotificationsState) {
    draft.selectedTab = tab;
  }
}
