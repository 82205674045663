import { computed, inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { NotificationsStore } from '@fc-core/notifications/store/notifications.store';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { urlMap } from '../../../../firebase-messaging/message-handler';
import { MomentService } from '@fc-core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacadeService {
  globalStore = inject(Store);
  router = inject(Router);
  notificationStore = inject(NotificationsStore);
  momentService = inject(MomentService);

  getNotifications(): Signal<EventHistoryItem[]> {
    return computed(() =>
      this.formatEvents(this.notificationStore.selectors.events()),
    );
  }

  private formatEvents(items: EventHistoryItem[]): EventHistoryItem[] {
    return items.map((item) => ({
      ...item,
      message: {
        ...item.message,
        link: {
          ...item.message.link,
          fullUrl: urlMap[item.message.link.type](item.message.link.id),
        },
      },
      readAt: this.momentService.timeAgo(item.created),
    }));
  }
}
