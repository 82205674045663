import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { Component, computed, inject, input, output } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { Store } from '@ngrx/store';
import { getEmployeeById } from '@fc-store/selectors';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';

@Component({
  selector: 'fc-event-item',
  imports: [MatRipple, RouterLink, IconComponent, AvatarPlaceholderModule],
  template: ` <div
    class="event-item"
    matRipple
    [routerLink]="event().message.link.fullUrl"
    (click)="markAsRead.emit(event().id)"
  >
    <div class="image-container">
      @if (event().message.image) {
        <img [src]="event().message.image" alt="event-image" />
      } @else {
        @if (event().message.type === 'event') {
          <fc-icon
            icon="icon-empty_state_vehicle"
            [size]="48"
            color="secondary200"
            class="event-icon"
          ></fc-icon>
        } @else {
          <fc-user-avatar
            [employee]="employee()"
            [size]="48"
            [fontSize]="24"
          ></fc-user-avatar>
        }
      }
    </div>
    <div class="event-info">
      <div class="event-title h3">
        {{ event().message.title }}
      </div>
      <div class="event-subtitle">
        {{ event().message.body }}
      </div>
    </div>

    <div class="event-timing">
      <div class="event-time label color-label">
        {{ event().readAt }}
      </div>
      @if (!event().read) {
        <div class="event-read"></div>
      }
    </div>
  </div>`,
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent {
  event = input<EventHistoryItem>();
  store = inject(Store);
  employee = computed(() => {
    if (this.event().message.type === 'chat') {
      return this.store.selectSignal(
        getEmployeeById(this.event().message.context.author.id),
      )();
    }
  });
  markAsRead = output<string>();
}
