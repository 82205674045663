import { Component, inject, input } from '@angular/core';
import { EmptyStateModule } from '@fc-shared/ui/empty-state/empty-state.module';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ScrollNearEndDirective } from '@fc-shared/directives/scroll-near-end.directive';
import { SkeletonModule } from '@fc-shared/ui/skeleton/skeleton.module';
import { NotificationsStore } from '@fc-core/notifications/store/notifications.store';
import { NotificationsFacadeService } from '@fc-core/notifications/store/notifications-facade.service';
import { EventItemComponent } from '@fc-core/notifications/events-tab/event-item.component';
import moment from 'moment-timezone';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'fc-event-list',
  imports: [
    EmptyStateModule,
    MatProgressSpinner,
    ScrollNearEndDirective,
    SkeletonModule,
    EventItemComponent,
    DatePipe,
  ],
  template: ` @if (loading()) {
      <div class="h-full">
        <fc-skeleton-list></fc-skeleton-list>
      </div>
    } @else {
      <div
        fcScrollNearEnd
        (nearEnd)="notificationsStore.loadMoreEvents()"
        class="h-full overflow-y-auto"
      >
        @for (event of notifications(); track i; let i = $index) {
          @if (
            i === 0 ||
            (event?.created | date) !== (notifications()[i - 1]?.created | date)
          ) {
            <div
              class="p-16 text-center text-sm text-gray-500 border-b border-gray-100"
            >
              <span>{{ formatMessageDateDivider(event?.created) }}</span>
            </div>
          }
          <fc-event-item
            [event]="event"
            (markAsRead)="notificationsStore.markAsRead($event)"
          />
        } @empty {
          <div class="empty-state">
            <fc-empty-state
              [size]="72"
              svgIcon="icon-empty_state_history"
              subtitle="You have no notifications yet"
            ></fc-empty-state>
          </div>
        }
      </div>
    }

    @if (notificationsStore.selectors.loadingMore()) {
      <div class="events-list-load-more">
        <mat-spinner
          class="spinner"
          diameter="30"
          strokeWidth="4"
        ></mat-spinner>
      </div>
    }`,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        overflow-y: hidden;
      }

      .events-list-load-more {
        width: 100%;
        padding: 16px 0;
        display: flex;
        justify-content: center;
      }
    `,
  ],
})
export class EventListComponent {
  selectedTab = input<string>();
  notificationsStore = inject(NotificationsStore);
  notificationsFacade = inject(NotificationsFacadeService);
  notifications = this.notificationsFacade.getNotifications();
  loading = this.notificationsStore.selectors.loading;

  formatMessageDateDivider(date: string): string {
    if (moment(date).isSame(moment(), 'day')) {
      return 'Today';
    }
    return moment(date).format('MMM D, YYYY');
  }
}
